import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO keywords={[`ha4gu`, `application`, `development`]} />
    <ul>
      <li>Blog: <a href="https://blog.ha4gu.com/">NERIMA DAMANSARA</a></li>
      <li>Twitter: <a href="https://twitter.com/ha4gu">@ha4gu</a></li>
      <li>GitHub: <a href="https://github.com/ha4gu">ha4gu</a></li>
      <li>AtCoder: <a href="https://atcoder.jp/users/ha4gu">ha4gu</a></li>
    </ul>
  </Layout>
)

export default IndexPage
